<template>
  <section class="agent-buttons">
    <a class="btn-primary " :href="`https://agents.${BackEndUrl}`" target="_blank">AGENTS ADMIN</a>
  </section>
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const BackEndUrl = "betmysport.com.mx"

      return {
        BackEndUrl
      }
    },
  };
</script>
